.main-profil {
  height: auto;
  width: 100%;
  word-spacing: -.2em;
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  padding: 50px 30px 0;
  font-size: .8em;
  display: flex;
}

.profil1 {
  color: #ffe0dc;
  width: 100%;
  justify-content: center;
  margin-bottom: 15px;
  font-family: ApercuProMono;
  display: flex;
}

.profil2 {
  width: 100%;
  height: auto;
  color: #ffe0dc;
  justify-content: center;
  font-family: ApercuProMono;
  display: flex;
}

.photo-profil {
  width: 100%;
  height: 100%;
  max-width: 75px;
  max-height: 75px;
  float: left;
  margin-right: 15px;
}

.fw-bold {
  -webkit-text-stroke-width: .4px;
  font-weight: bold;
}

.color-red {
  color: red;
}

.profil1, .profil2 {
  opacity: 0;
  transition: opacity .8s ease-in;
}

.profil-opacity-off {
  opacity: 1;
}

@media screen and (min-width: 0) and (max-width: 424px) {
  .main-profil {
    padding: 50px 30px 0;
    font-size: 16px;
  }

  .photo-profil {
    max-width: 70px;
    max-height: 70px;
  }

  .profil1, .profil2 {
    font-size: .8em;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .main-profil {
    padding: 100px 150px;
    font-size: 16px;
  }

  .photo-profil {
    max-width: 75px;
    max-height: 75px;
  }

  .profil1, .profil2 {
    font-size: .9em;
  }
}

@media screen and (min-width: 768px) {
  .main-profil {
    padding: 15vh 20vw;
    font-size: 16px;
  }

  .photo-profil {
    max-width: 80px;
    max-height: 80px;
  }

  .profil1, .profil2 {
    font-size: 1.1em;
  }
}

/*# sourceMappingURL=index.3994922f.css.map */
