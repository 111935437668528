.main-profil {
  display: flex;
  height: auto;
  padding: 50px 30px 0px 30px;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8em;
  word-spacing: -0.2em;
}

.profil1 {
  font-family: "ApercuProMono";
  color: #ffe0dc;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.profil2 {
  display: flex;
  width: 100%;
  height: auto;
  font-family: "ApercuProMono";
  color: #ffe0dc;
  justify-content: center;
}

.photo-profil {
  width: 100%;
  height: 100%;
  max-width: 75px;
  max-height: 75px;
  float: left;
  margin-right: 15px;
}

.fw-bold {
  font-weight: bold;
  -webkit-text-stroke-width: 0.4px;
}

.color-red {
  color: red;
}

.profil1,
.profil2 {
  opacity: 0;
  transition: 0.8s opacity ease-in;
}

.profil-opacity-off {
  opacity: 1;
}

@media screen and (min-width: 0px) and (max-width: 424px) {
  .main-profil {
    padding: 50px 30px 0px;
    font-size: 16px;
  }
  .photo-profil {
    max-width: 70px;
    max-height: 70px;
  }
  .profil1,
  .profil2 {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .main-profil {
    padding: 100px 150px 100px;
    font-size: 16px;
  }
  .photo-profil {
    max-width: 75px;
    max-height: 75px;
  }
  .profil1,
  .profil2 {
    font-size: 0.9em;
  }
}
@media screen and (min-width: 768px) {
  .main-profil {
    padding: 15vh 20vw 15vh;
    font-size: 16px;
  }
  .photo-profil {
    max-width: 80px;
    max-height: 80px;
  }
  .profil1,
  .profil2 {
    font-size: 1.1em;
  }
}
